* {
    margin: 0;
    padding: 0;
   
  }

  .invisible {
    width: 0 !important;
    height: 0 !important;
  }

  .hidden {
    visibility: hidden;
  }

  .app {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
  }

  // .header {
  //   background-color: white;
  //   height: 10%;
  //   width: 90%;
  //   z-index: 3;
  //   position: fixed;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   gap: 50px;
  //   border-bottom: 2px solid var(--light-grey);
  //   padding: 0 100px;
  //   & span {
  //     font-weight: bold;
  //     font-size: 20px;
  //   }
  // }
  
  .modelwrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: #000000;
    & .model {
        width: 66% !important;
        height: 100%;
        position: relative;
    }
    &   .fullWidth {
      width: 100vw !important;
    }
  }

  .fullWidth {
    width: 100vw !important;
  }

  .smallerViewport {
    height: 40vh;
  }

  .materialsOverviewWrapper {
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: rgba(214,214,214,0.8);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    & .materialsOverview {
      position: absolute;
      z-index: 10;
      right: 0;
      width: 70%;
      height: 100%;
      background-color: white;
      color: black;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}

.materialsOverviewContainer {
  display: flex;
  flex-direction: column;
  & .korpusNote {
    max-width: 700px;
    margin: 0;
  }
  & .materialsOverviewContainerHeadline {
    color: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    margin-top: 25px;
  }
  & .materialsOverviewElementSelection {
    display: flex;
    gap: 0;
    margin: 50px 0; 
    align-items: flex-end;
    flex-wrap: wrap;
    & div {
        text-align: center;
    }
  }
  & .materialsTypeSelection {
    display: flex;
    gap: 25px;
    margin: 25px 0;
    & span {
      flex: 0 1 0;
      font-size: 16px;
    }
  }
  & .materialsSelection {
    display: flex;
    row-gap: 25px;
    overflow-x: hidden;
    max-width: 1050px;
    column-gap: 25px;
    margin: 25px 0;
    padding: 5px 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 150px;
    scrollbar-width: thin !important;
    scrollbar-color: #ffffff var(--dark-brown) !important; 
    & div {
      width: 180px;
      & img {
        width: 100%;
        max-width: 100px;
        display: block;
        margin: 0 auto;
    }
    & p {
      white-space: nowrap;
      text-align: center;
      margin: 10px 0;
    }
  }
}
}

.selectableElement {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 50px;
  width: 12%;
  & img {
    height: 60px;
    margin-bottom: 10px;
  }
  & p {
    font-size: 13px;
    margin: 0 !important;
  }
}

.backContainer {
  position: fixed;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 80px;
  width: 66%;
  top: 0;
  z-index: 10;
  padding: 0 25px;
  & span {
    margin-left: 20px
  }
}

.mediumElementPicture{
  height: 90px !important;
}
.biggerElementPicture{
  height: 110px !important;
}

.selectedElement {
  background-color: var(--selected-grey);
}

.selectedMaterial {
  box-shadow: 0 0 0 4px var(--primary);
}

.sizes {
  width: 66vw;
  height: 80px;
  padding: 0 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #F0F0F0;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  gap: 50px;
  & span {
    color: black;
  }
}

/* General classes */

.pointer{
  cursor: pointer;
}

.boldFont {
  font-weight: bold;
}

.loadingContainer {
  background-color: #ffffff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  width: 66%;
  height: 100%;

  & img {
    width: 28vw;
    height: unset;
    padding-left: 30px;
  }
}

/* HTML Label */


.labelHtml {
  border: none;
  position: absolute;
  padding: 5px 15px;
  background-color: #ffffff;
  border: 2px solid transparent;
  border-radius: 25px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  & img {
    width: 20px;
    margin-right: 25px;
  }
  & p {
    white-space: nowrap;
    margin: 0;
  }
}

.labelHtmlX {
  right: -67px;
  bottom: -23px;
  transform: rotate(90deg);
  position: relative;
  width: 50px;
  height: 50px;
}

.labelHtmlY {
  left: -25px;
  bottom: 120px;
  position: relative;
  width: 50px;
  height: 50px;
}

.labelHtmlYleft {
  left: -89px;
  bottom: 142px;
  transform: rotate(-45deg);
  position: relative;
  width: 50px;
  height: 50px;
}

.labelHtmlYright {
  right: -40px;
  bottom: 193px;
  position: relative;
  transform: rotate(45deg);
  width: 50px;
  height: 50px;
}

.labelHtmlYdouble {
  right: 0;
  bottom: 330px;
  position: relative;
  width: 50px;
  height: 50px;
}

.deleteButton {
  right: 20px;
  bottom: 150px;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: none;
  & img {
    width: 40px;
    height: 40px;
  }
}

.labelHtmlStart {
  left: 32%;
  top: 57%;
  transform: translateX(-50%);
  padding: 5px 15px;
  border-radius: 25px;
  color: #000000;
  font-size: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  & img {
    margin-right: 15px;
  }
}

.transparentAxes {
  opacity: 0.4;
}

.labelContainer {
  width: 1px;
  height: 1px;
  position: fixed;
  z-index: 9;
}



// Steuerung
.controls {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  left: 50px;
  z-index: 1;
  transition: 0.5s;
  cursor: help;
  & img {
    width: 35px;
    height: 35px;
  }
  &:hover {
    transform: scale(1.1);
}
}

.controlsModal{
  width: 66%;
  height: 100%;
  background: #ffffff;
  position: absolute;
  z-index: 30;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  overflow-x: hidden;
  overflow-y: scroll;
}

.showModal {
  opacity: 1;
}

.hideModal{
  opacity: 0;
  pointer-events: none;
}

.modalContent{
  margin-top: 100px;
  color: #000000;
  padding: 0 25px;
  & .controlsHeadline {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  & ul {
    margin-left: 25px;
  }
  h2, p {
    color: #000000;
  }
  p {
    margin: 25px;
  }
  img {
    width: 30px;
    height: 30px;
  }
}

.modalBackground {
  width: 100vw !important;
  height: 100%;
  background: rgba(236, 233, 231, 0.5);
  position: absolute;
  z-index: 30;
  opacity: 1;
  transition: opacity 0.5s;
}

.popupModal {
  position: absolute;
  z-index: 10;
  background-color: white;
  width: 40%;
  min-height: 190px;
  max-height: 300px;
  text-align: center;
  border: 2px solid black;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 25px;
}

.summaryModal {
  max-height: 550px;
}

.hidePopupModal {
  opacity: 0;
  pointer-events: none;
}

.summaryContainer {
  margin: 50px 0 25px;
  overflow-y: scroll;
  max-height: 200px;
  padding: 5px;
  &::-webkit-scrollbar {
    width: 6px;
}
&::-webkit-scrollbar-track {
    background-color: #ffffff; /* Color of the scroll track for WebKit */
}

&::-webkit-scrollbar-thumb {
    background-color: var(--dark-brown); /* Color of the thumb for WebKit */
    border-radius: 5px;
}
}

.stockItem {
  display: flex;
  justify-content: space-between;
}

.quantity {
  width: 60px;
  display: inline-block;
}

.gesamtsumme {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0 10px;
  & p {
    margin: 0;
  }
}

.gesamtsumme p:first-child {
  flex: 1 0 100%;
  text-align: right;
  font-weight: bold;
}

.gesamtsumme p:nth-child(2) {
  font-size: 12px;
}


/* Animation Containter */ 
.animationContainer {
  width: 100%;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 80%;
    height: 80%;
  }
}

// Queries 

@media (max-width: 1650px) {
  .sizes span {
    font-size: 16px;
  }
}

@media (max-width: 1400px){
  .modelwrapper .model {
      width: 100% !important;
  }
  .loadingContainer {
    width: 100%;
    height: inherit;
  }
  .materialsOverviewWrapper .materialsOverview {
    width: 100%;
  }
  .materialsOverviewElementSelection, .materialsTypeSelection {
    flex-wrap: wrap;
  }
  .labelHtmlStart {
    left: 50%;
  }
  .sizes{
    width: 100vw;
    gap: 5px;
    flex-direction: column;
    padding: 10px 0;
    height: auto;
    & span {
      font-size: 13px;
    }
  }
  .labelHtmlStart p {
    font-size: 13px;
}
.controlsModal {
  width: 100%;
  padding: 0;
}
.modalContent {
  padding: 10px;
  & li {
    font-size: 16px;
  }
}
.backContainer{
  padding-left: 10px;
  width: 100%;
  position: relative;
}
.controls {
  width: 40px;
  height: 40px;
  left: 20px;
  bottom: 75px;
  & img{
    width: 30px;
    height: 30px;
  }
}
.popupModal {
  width: 90%;
  padding: 50px 10px;
  position: fixed;
}
}

@media (max-width: 768px){
  .materialsOverviewContainer {
    padding: 0 10px;
  }
  .materialsOverviewContainer .materialsTypeSelection span {
    flex: 1 0 100%;
  }
  /*
  .labelContainer {
    width: 320px;
    height: 220px;
    position: absolute;
  }
  .labelHtmlX {
    right: 0;
    bottom: 0;
    position: relative;
  }
  .labelHtmlY {
    left: 0;
    bottom: 0;
    position: relative;
  }
  .labelHtmlYleft {
    left: 0;
    bottom: 0;
    position: relative;
  }
  .labelHtmlYright {
    right: 0;
    bottom: 0;
    position: relative;
  }
  .labelHtmlYdouble{
    right: 0;
    bottom: 0;
    position: relative;
  }
  .labelHtml {
    margin: 15px 0;
  }
  .deleteButton {
    left: 0;
    bottom: 0;
    width: 100%;
    position: relative;
  }*/
  .summaryContainer span {
    font-size: 3.5vw;
  }
  .summaryContainer {
    max-height: 50vh;
  }
  .summaryModal {
    width: 100vw;
    height: 100vh;
    max-height: none;
  }
}

@media (max-width: 480px) {
  .selectableElement {
    justify-content: center;
    padding: 0;
    width: auto;
    flex: 0 0 45%;
    height: 120px;
  }
  .loadingContainer img {
    width: 100vw;
    height: unset;
}
.materialsSelection {
  max-height: none !important;
}
.materialsSelection p {
  font-size: 12px;
}
.materialsSelection div {
  flex: 0 0 45%;
  margin: 0;
}
}


@media (max-width: 450px){
  .labelContainer {
    width: 220px;
  }
  .labelHtml p {
    font-size: 13px;
  }
  .labelHtml img {
    margin-right: 3px;
  }
}
